import './Header.css'
import { GiHamburgerMenu } from 'react-icons/gi';
// import { FaPhoneVolume } from "react-icons/fa";
import { useEffect, useState } from 'react';

export default function Header() {
    const [showMediaIcons,setShowMediaIcons] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
          setIsScrolled(window.scrollY > 10);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className={`header w-full ${isScrolled ? 'scrolled' : 'transparent'}`}>
        
        <div className='web-logo'>
        <img className="logo" src="images/nav-logo2.png" alt='Logo'/>
        </div>

        <nav className= {showMediaIcons ? "mobile-navbar" : 'navbar'} >
          <ul className= {showMediaIcons ? "mobile-navbar-list" : 'navbar-list '}>
            <li><a href="#home" className='navbar-link text-dark no-underline font-bold'>Home</a></li>
            <li><a href="#details" className='navbar-link text-dark no-underline'>Details</a></li>
            <li><a href="#plans" className='navbar-link text-dark no-underline'>Plans</a></li>
            <li><a href="#location" className='navbar-link text-dark no-underline'>Location</a></li>
            <li><a href="#gallery" className='navbar-link text-dark no-underline'>Gallery</a></li>
            <li><a href="#footer" className='navbar-link text-dark no-underline'>Contact</a></li>
          </ul>

        </nav>
        {/* <a href="tel:+" className="text-3xl text-blue-500 jumping-icon">
          <FaPhoneVolume />
        </a> */}
  
        <div className='mobile-navbar-btn'>
          <button name='ham' className='mobile-nav-icon' onClick={() => setShowMediaIcons(!showMediaIcons)}>
            <GiHamburgerMenu size={40}/>
          </button>
        </div>
      </header>
  )
}
