import React, { useState } from 'react';
import PopForm from './PopForm';

export default function InfoSection() {
    const [isPopFormOpen, setIsPopFormOpen] = useState(false);

  const handleInquireNowClick = () => {
    setIsPopFormOpen(true);
  };

  const closePopForm = () => {
    setIsPopFormOpen(false);
  };

  return (
    <div className="hidden md:flex fixed bottom-0 w-full bg-white py-3 px-4 shadow-lg border-t border-gray-300">
      <div className="flex justify-between items-center max-w-6xl mx-auto text-center text-black space-x-8">
        
        {/* Logo and Location */}
        <div className="flex items-center space-x-2">
          <img src="images/omaxe-state-logo.png" alt="Omaxe State Logo" className="w-30 h-20 filter invert pr-5" />
          <p className="font-semibold text-sm">Sector 19B, Dwarka</p>
        </div>

        {/* Payment Plan */}
        <div className="text-center space-y-1">
          <p className="text-gray-800 font-medium text-xs">PAYMENT PLAN</p>
          <p className="text-black text-lg font-semibold">50 : 50</p>
        </div>

        {/* Loyalty Charges */}
        <div className="text-center space-y-1">
          <p className="text-gray-800 font-medium text-xs">LOYALTY CHARGES</p>
          <p className="text-black text-lg font-semibold">12%</p>
        </div>

        {/* Investment Starts At */}
        <div className="text-center space-y-1">
          <p className="text-gray-800 font-medium text-xs">INVESTMENT STARTS AT</p>
          <p className="text-black text-lg font-semibold">₹ 37.5 LACS*</p>
          <p className="text-gray-600 text-xs">(Limited Units Available)</p>
        </div>

        {/* Inquire Now Button */}
        <div>
          <button
          onClick={handleInquireNowClick}
          className="bg-black text-white text-sm px-4 py-2 rounded-full hover:bg-gray-900 transition duration-200 ease-in-out">
            Enquire Now
          </button>
        </div>
      </div>
      {/* PopForm component */}
      <PopForm
        isOpen={isPopFormOpen}
        onClose={closePopForm}
        title="Inquiry Form"
      />
    </div>
  );
}
