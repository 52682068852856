import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import About from './Component/About';
import Amenities from './Component/Amenities';
import Category from './Component/Category';
import Connectivity from './Component/Connectivity';
import Footer from './Component/Footer';
import Gallery from './Component/Gallery';
import Header from './Component/Header';
import Home from './Component/Home';
import WhatsAppIcon from './Component/WhatsAppIcon';
import MobHome from './Component/MobHome';
import FloorPlan from './Component/FloorPlan';
import InfoSection from './Component/InfoSection';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showInfoSection, setShowInfoSection] = useState(true);
  const footerRef = useRef(null);

  useEffect(() => {
    // Function to handle window resize for mobile view
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Store the ref in a local variable to avoid stale reference warnings
    const footerElement = footerRef.current;

    // Intersection Observer to detect footer visibility
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowInfoSection(!entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the footer is visible
    );

    if (footerElement) {
      observer.observe(footerElement);
    }

    return () => {
      if (footerElement) {
        observer.unobserve(footerElement);
      }
    };
  }, []);

  return (
    <div className="App bg-gray-300">
      <Header />
      {isMobile ? <MobHome /> : <Home />}
      <About />
      <Category />
      <FloorPlan />
      <Connectivity />
      <Amenities />
      <Gallery />
      <Footer ref={footerRef} />
      <WhatsAppIcon />
      {showInfoSection && <InfoSection />}
    </div>
  );
}

export default App;
