// FloorPlan.js
import React from 'react';

const floorPlans = [
  { id: 1, title: 'Ground Floor', imageUrl: 'images/floorG.jpg' },
  { id: 2, title: 'First Floor', imageUrl: 'images/floor1.jpg' },
  { id: 3, title: 'Second Floor', imageUrl: 'images/floor2.jpg' },
  { id: 4, title: 'Third Floor', imageUrl: 'images/floor3.jpg' },
];

function FloorPlan() {
  return (
    <div className="bg-white py-10 px-5" id='plans'>
      <h2 className="text-center text-2xl font-bold mb-6">Floor Plans</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {floorPlans.map((plan) => (
          <div
            key={plan.id}
            className="bg-gray-200 rounded-lg shadow-md overflow-hidden"
          >
            <a href={plan.imageUrl} target="_blank" rel="noopener noreferrer">
              <img src={plan.imageUrl} alt={plan.title} className="w-full h-48 object-cover" />
            </a>
            <div className="p-4">
              <h3 className="text-xl font-semibold">{plan.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FloorPlan;
