import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Gallery() {
  return (
    <div className='mt-3' id='gallery'>
        <div className="text-center">
        <p className="text-8xl font-bold relative inline-block text-[100px] leading-[60px] text-[#F5F5F5] font-extrabold z-10 mt-3">
          Gallery
        </p>
        <p className="m-0 text-[46px] leading-[64px] font-medium mt-[-70px] tracking-[-1.12px] relative block z-20">
          Gallery
        </p>
      </div>

      <div className=" mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="aspect-w-4 aspect-h-3">
          <LazyLoadImage effect="blur" src="images/gallery1.jpg" alt="Amenity 1" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3">
          <LazyLoadImage effect="blur" src="images/gallery2.jpg" alt="Amenity 2" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3">
          <LazyLoadImage effect="blur" src="images/gallery3.jpg" alt="Amenity 3" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3">
          <LazyLoadImage effect="blur" src="images/gallery4.jpg" alt="Amenity 4" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3">
          <LazyLoadImage effect="blur" src="images/gallery5.jpg" alt="Amenity 5" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3" >
          <LazyLoadImage effect="blur" src="images/gallery6.jpg" alt="Amenity 6" className="w-full h-full object-cover rounded-lg" />
        </div>
      </div>
    </div>
    </div>
  )
}
