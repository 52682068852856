import React from 'react'
import { FaParking, FaUtensils, FaDog, FaBatteryFull, FaGlassCheers, FaHotel } from 'react-icons/fa';

const facilitiesData = [
  { icon: <FaParking size={50} />, label: 'Advance Security' },
  { icon: <FaUtensils size={50} />, label: 'Restaurant' },
  { icon: <FaDog size={50} />, label: 'Pet Friendly' },
  { icon: <FaBatteryFull size={50} />, label: 'Power Backup' },
  { icon: <FaGlassCheers size={50} />, label: 'Bar' },
  { icon: <FaHotel size={50} />, label: 'Lifts & Escalators' },
];

export default function Amenities() {
  return (
    <div className="mt-3 px-4 md:px-8">
      <div className="text-center mb-8">
        <h2 className="text-4xl sm:text-5xl md:text-8xl font-extrabold text-[#F5F5F5] relative inline-block z-10" id='amenities'>
        Amenities
        </h2>
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-medium mt-[-30px] sm:mt-[-20px] md:mt-[-20px] tracking-tight relative block z-20">
          Amenities
        </h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
        {facilitiesData.map((facility, index) => (
          <div
            key={index}
            className="group flex flex-col items-center justify-center p-4 m-2 bg-white shadow-md rounded-full transition-all duration-500 ease-in-out custom-gradient-background hover:bg-blue-900 hover:shadow-xl"
          >
            <div className="transition-transform transform group-hover:translate-y-[-5px] duration-300">
              {facility.icon}
            </div>
            <p className="mt-4 text-lg font-semibold text-black transition-colors duration-300 group-hover:text-white">
              {facility.label}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
