import axios from "axios";
import React, { useState, useEffect } from "react";

export default function MobHome() {
  const [currentImage, setCurrentImage] = useState(0);
  const [user, setUser] = useState({
    fname: '',
    mobile: '',
    description: ''
  });
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({
    type: '',
    message: '',
    visible: false
});
  const images = ["images/stadium.jpg", "images/carousel4.jpg", "images/carousel6.jpg"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const dataSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!user.fname.trim()) validationErrors.fname = 'Your name is required';
    if (!user.mobile.trim()) validationErrors.mobile = 'Mobile No. is required';
    else if (!/^\d{10}$/.test(user.mobile)) validationErrors.mobile = 'Mobile number is not valid';

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
        axios.post("https://api.omaxemallsector19.in/userEnquiry", user)
            .then(res => {
                setNotification({
                    type: 'success',
                    message: res.data.msg,
                    visible: true
                });
                setUser({ fname: '', mobile: '', description: '' });
            })
            .catch(err => {
                setNotification({
                    type: 'error',
                    message: err.response?.data?.error || "Something went wrong!",
                    visible: true
                });
            });
    }
};

  const handleInputChange = (e) => {
    setUser({...user, [e.target.name]: e.target.value });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
};

  return (
    <div className="w-full">
      {/* Carousel Section */}
      <div 
        className="relative w-full h-64 bg-cover bg-center"
        style={{ backgroundImage: `url(${images[currentImage]})` }}
        id="home"
      >
        {/* Optional Carousel Overlay Text */}
        <div className="absolute inset-0 bg-gray-900 bg-opacity-30 flex flex-col items-center justify-center space-y-2">
    <img 
      src="images/omaxe-state-logo.png" 
      alt="Omaxe State Logo" 
      className="w-60 h-auto pt-10" 
    />
    <h2 className="text-white text-lg font-semibold">Welcome to The Omaxe State</h2>
  </div>
      </div>

      {/* Form Section */}
      <div className="bg-blue-500 p-6 rounded-lg shadow-lg mt-4 mx-4">
        <h2 className="text-white text-lg font-semibold mb-4">Get In Touch!</h2>
        <form className="space-y-4" onSubmit={dataSubmit}>
          <input
            type="text"
            placeholder="Name*"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            name="fname"
            value={user.fname}
            onChange={handleInputChange}
          />
          {errors.fname && <p className="text-red-500 text-sm">{errors.fname}</p>}
          <input
            type="number"
            placeholder="Mobile No.*"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            name="mobile"
            value={user.mobile}
            onChange={handleInputChange}
          />
          {errors.mobile && <p className="text-red-500 text-sm">{errors.mobile}</p>}
          <textarea
            placeholder="Your Message"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24"
            name="description"
            value={user.description}
            onChange={handleInputChange}
          ></textarea>
          <button
            type="submit"
            className="w-full bg-red-500 text-white p-3 rounded-md hover:bg-red-600"
          >
            Submit
          </button>
        </form>
        {/* Notification Area */}
        {notification.visible && (
                    <div className={`mt-4 p-4 rounded-lg text-white ${notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'} flex justify-between items-center`}>
                        <p>{notification.message}</p>
                        <button className="font-bold ml-4" onClick={closeNotification}>X</button>
                    </div>
                )}
      </div>
    </div>
    
  );
}
