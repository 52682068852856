import axios from "axios";
import React, { useState, useEffect } from "react";

export default function Home() {
  const [user, setUser] = useState({
    fname: "",
    mobile: "",
    description: "",
  });

  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({
    type: "",
    message: "",
    visible: false,
  });

  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    "images/stadium.jpg",
    "images/carousel4.jpg",
    "images/carousel6.jpg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const dataSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!user.fname.trim()) validationErrors.fname = "Your name is required";
    if (!user.mobile.trim()) validationErrors.mobile = "Mobile No. is required";
    else if (!/^\d{10}$/.test(user.mobile))
      validationErrors.mobile = "Mobile number is not valid";

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      axios
        .post("https://api.omaxemallsector19.in/userEnquiry", user)
        .then((res) => {
          localStorage.setItem("formSubmitted", "true");
          setNotification({
            type: "success",
            message: res.data.msg,
            visible: true,
          });
          setUser({ fname: "", mobile: "", description: "" });
        })
        .catch((err) => {
          setNotification({
            type: "error",
            message: err.response?.data?.error || "Something went wrong!",
            visible: true,
          });
        });
    }
  };

  const inputHandler = (e) => {
    return setUser({ ...user, [e.target.name]: e.target.value });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  return (
    <div
      className="relative h-screen w-full bg-cover bg-center flex flex-col md:flex-row items-center justify-center transition-all duration-500"
      style={{
        backgroundImage: `url(${images[currentImage]})`,
      }}
      id="home"
    >
      {/* Text Section */}
      <div className="absolute left-4 md:left-10 top-1/3 bg-gray-600 bg-opacity-50 text-white p-4 md:p-6 rounded-lg max-w-xs md:max-w-md shadow-lg">
        <img src="images/omaxe-state-logo.png" alt="Omaxe State Logo" className="text-xl md:text-3xl font-bold mb-2" />
        <p className="text-sm md:text-lg">Dwarka, Delhi</p>
      </div>

      {/* Form Section */}
      <div className="absolute right-4 md:right-10 top-1/2 md:top-1/4 transform -translate-y-1/2 md:transform-none bg-gray-800 bg-opacity-80 p-4 md:p-8 rounded-lg shadow-lg w-11/12 max-w-sm md:max-w-md">
        <h2 className="text-white text-lg md:text-xl font-semibold mb-4 md:mb-6">
          DROP AN ENQUIRY
        </h2>
        <form className="space-y-3 md:space-y-4" onSubmit={dataSubmit}>
          <input
            type="text"
            placeholder="Name*"
            className="w-full p-3 md:p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300"
            name="fname"
            value={user?.fname}
            onChange={inputHandler}
          />
          {errors.fname && (
            <p className="text-red-500 text-sm">{errors.fname}</p>
          )}

          <input
            type="number"
            placeholder="Mobile No.*"
            className="w-full p-3 md:p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300"
            name="mobile"
            value={user?.mobile}
            onChange={inputHandler}
          />
          {errors.mobile && (
            <p className="text-red-500 text-sm">{errors.mobile}</p>
          )}

          <textarea
            placeholder="Your Message"
            className="w-full p-3 md:p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300 h-24 md:h-32 resize-none"
            name="description"
            value={user?.description}
            onChange={inputHandler}
          ></textarea>

          <button
            type="submit"
            className="w-full bg-red-700 text-white py-2 md:py-3 rounded-lg hover:bg-red-800 transition duration-200 font-semibold"
          >
            Submit
          </button>
        </form>

        {/* Notification Area */}
        {notification.visible && (
          <div
            className={`mt-3 md:mt-4 p-3 md:p-4 rounded-lg text-white ${
              notification.type === "success" ? "bg-green-500" : "bg-red-500"
            } flex justify-between items-center`}
          >
            <p className="text-sm">{notification.message}</p>
            <button className="font-bold ml-2 md:ml-4" onClick={closeNotification}>
              X
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
