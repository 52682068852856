import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PopForm = ({ isOpen, onClose, title, onFormSubmitted }) => {
  const [user, setUser] = useState({
    fname: '',
    lname: '',
    mobile: '',
    description: ''
  });

  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({
    type: '',
    message: '',
    visible: false
  });

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!user.fname.trim()) {
      validationErrors.fname = 'Your name is required';
    }
    if (!user.mobile.trim()) {
      validationErrors.mobile = 'Mobile No. is required';
    } else if (!/^\d{10}$/.test(user.mobile)) {
      validationErrors.mobile = 'Mobile number is not valid';
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      axios.post("https://api.omaxemallsector19.in/userEnquiry", user)
        .then(res => {
          localStorage.setItem('formSubmitted', 'true');
          toast.success(res.data.msg, {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          setUser({ fname: '', lname: '', mobile: '', description: '' });
          setTimeout(onClose, 4000);
        })
        .catch(err => {
          toast.error(err.response.data.error || "Something went wrong!", {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        });
    }
  };

  const inputHandler = (e) => {
    return setUser({ ...user, [e.target.name]: e.target.value });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  return (
    <>
      <ToastContainer />
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-[#1b1b1b] rounded-lg shadow-lg p-6 md:p-8 lg:p-10 relative w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3 max-h-[90vh] overflow-y-auto">
        <button
  className="absolute top-12 right-4 text-gray-600 hover:text-gray-900"
  onClick={onClose}
  style={{ zIndex: 100 }} // Ensure the button is on top
>
  <FaTimes className="text-2xl" />
</button>
          {title && <h2 className="text-xl md:text-2xl font-bold mb-4 text-white text-center">{title}</h2>}

          {/* Form Content */}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Full Name*"
                className={`w-full bg-gray-800 text-white border ${errors.fname ? 'border-red-500' : 'border-gray-700'} focus:border-gray-500 rounded-md p-3 md:p-4`}
                name='fname'
                value={user?.fname}
                onChange={inputHandler}
              />
              {errors.fname && <p className="text-red-500 text-sm mt-1">{errors.fname}</p>}
            </div>
            <div className="mb-4">
              <input
                type="number"
                placeholder="Mobile No.*"
                className={`w-full bg-gray-800 text-white border ${errors.mobile ? 'border-red-500' : 'border-gray-700'} focus:border-gray-500 rounded-md p-3 md:p-4`}
                name='mobile'
                value={user?.mobile}
                onChange={inputHandler}
              />
              {errors.mobile && <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>}
            </div>
            <textarea
              placeholder="Your Message"
              className="w-full bg-gray-800 text-white border border-gray-700 focus:border-gray-500 rounded-md p-3 md:p-4 resize-none h-24 md:h-32"
              name='description'
              value={user?.description}
              onChange={inputHandler}
            ></textarea>
            <button
              type="submit"
              className="w-full bg-red-600 text-white py-2 md:py-3 rounded-md hover:bg-red-700 transition duration-300 mt-4"
            >
              Submit
            </button>
          </form>

          {/* Notification Area */}
          {notification.visible && (
            <div className={`mt-4 p-4 rounded-lg text-white ${notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'} flex justify-between items-center`}>
              <p>{notification.message}</p>
              <button className="font-bold ml-4" onClick={closeNotification}>X</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PopForm;
