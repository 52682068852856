import React, {useEffect, useState} from 'react'
import PopForm from './PopForm';


export default function Category() {
    const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalOpen(true);
    }, 12000); // 12 seconds delay

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);
  return (
    <section className="flex flex-col gap-20 lg:gap-32 p-10 lg:p-24 bg-gray-100" id='details'>
      {/* First Row: Image on Left, Text on Right */}
      <div className="flex flex-col lg:flex-row items-center justify-center">
        {/* Image Container with Orange Background */}
        <div className="relative mb-10 lg:mb-0 lg:mr-20">
          {/* Orange Background Box */}
          <div className="absolute top-4 left-4 w-full h-full bg-orange-500 transform translate-x-6 translate-y-6"></div>

          {/* Image with Increased Size */}
          <div className="relative border-4 border-white p-2 max-w-md lg:max-w-md bg-white shadow-lg">
            <img
              src="images/gallery3.jpg" // Replace with your image path
              alt="Retail Shop"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>

        {/* Text Content */}
        <div className="max-w-xl text-gray-800">
          <h2 className="text-3xl font-bold text-orange-600 mb-6">Ground Floor Retail Shops</h2>
          <hr className="border-t-2 border-dashed border-orange-500 mb-6 w-1/3" />
          <p className="text-lg text-gray-600 leading-relaxed">
          The Omaxe State offers an upscale destination for retail and leisure, boasting a strategic layout where over 75% of its retail spaces are located on the ground level. It features climate-controlled corridors and shop areas that vary between 450 sq. ft. and 33,000 sq. ft., accommodating diverse retail needs.
          </p>
          <div className="flex justify-end mt-6">
      <button className="px-4 py-2 bg-orange-600 text-white rounded hover:bg-orange-700" onClick={handleOpenModal}>
        Book Now
      </button>
    </div>

        </div>
      </div>

      {/* Second Row: Text on Left, Image on Right */}
      <div className="flex flex-col lg:flex-row-reverse items-center justify-center pt-5">
        {/* Image Container with Orange Background */}
        <div className="relative mb-10 lg:mb-0 lg:ml-20">
          {/* Orange Background Box */}
          <div className="absolute top-4 right-4 w-full h-full bg-orange-500 transform -translate-x-6 translate-y-6"></div>

          {/* Image with Increased Size */}
          <div className="relative border-4 border-white p-2 max-w-md lg:max-w-md bg-white shadow-lg">
            <img
              src="images/Multiplex.jpg" // Replace with your second image path
              alt="Multiplex"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>

        {/* Text Content */}
        <div className="max-w-xl text-gray-800">
          <h2 className="text-3xl font-bold text-orange-600 mb-6">Multiplex</h2>
          <hr className="border-t-2 border-dashed border-orange-500 mb-6 w-1/3" />
          <p className="text-lg text-gray-600 leading-relaxed">
          With investment opportunities starting at ₹37.5 lakh per unit, the multiplex assures strong returns and lease guarantees, creating a secure investment option. Investors can expect consistent appreciation in value, high returns, and the flexibility of tailored payment plans, making it an attractive choice for both seasoned and first-time commercial investors.
          </p>
          <div className="flex justify-start mt-6">
      <button className="px-4 py-2 bg-orange-600 text-white rounded hover:bg-orange-700" onClick={handleOpenModal}>
        Book Now
      </button>
    </div>
        </div>
      </div>

      {/* Third Row: Image on Left, Text on Right */}
      <div className="flex flex-col lg:flex-row items-center justify-center">
        {/* Image Container with Orange Background */}
        <div className="relative mb-10 lg:mb-0 lg:mr-20">
          {/* Orange Background Box */}
          <div className="absolute top-4 left-4 w-full h-full bg-orange-500 transform translate-x-6 translate-y-6"></div>

          {/* Image with Increased Size */}
          <div className="relative border-4 border-white p-2 max-w-md lg:max-w-md bg-white shadow-lg h-[250px]">
            <img
              src="images/stadium.jpg" // Replace with your image path
              alt="Retail Shop"
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Text Content */}
        <div className="max-w-xl text-gray-800">
          <h2 className="text-3xl font-bold text-orange-600 mb-6">Ground Floor Retail Shops</h2>
          <hr className="border-t-2 border-dashed border-orange-500 mb-6 w-1/3" />
          <p className="text-lg text-gray-600 leading-relaxed">
          The Omaxe State offers an upscale destination for retail and leisure, boasting a strategic layout where over 75% of its retail spaces are located on the ground level. It features climate-controlled corridors and shop areas that vary between 450 sq. ft. and 33,000 sq. ft., accommodating diverse retail needs.
          </p>
          <div className="flex justify-end mt-6">
      <button className="px-4 py-2 bg-orange-600 text-white rounded hover:bg-orange-700" onClick={handleOpenModal}>
        Book Now
      </button>
    </div>

        </div>
      </div>


      {/* Modal with Form */}
      <PopForm
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Get Details"
      />
    </section>
  )
}
