import { useState } from "react";
import PopForm from "./PopForm";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Carousel.css'

export default function About() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
  
    return (
        <div className="flex flex-col lg:flex-row bg-white m-5 shadow-lg rounded-lg overflow-hidden" id='about'>
            {/* Left Image Section */}
            <div className="lg:w-1/2 w-full h-64 lg:h-auto flex items-center justify-center overflow-hidden">
                <img className="check w-full h-full" src="images/about.jpg" alt="Omaxe Dwarka Overview"/>
            </div>


            {/* Right Content Section */}
            <div className="lg:w-1/2 w-full bg-gray-200 p-6 sm:p-8">
                <h2 className="text-red-600 text-2xl font-semibold text-center underline mb-4">
                    <span className="inline-block transform rotate-[4deg]">A</span>
                    <span className="inline-block transform rotate-[4deg]">b</span>
                    <span className="inline-block transform rotate-[4deg]">o</span>
                    <span className="inline-block transform rotate-[4deg]">u</span>
                    <span className="inline-block transform rotate-[4deg]">t</span>
                    <span className="inline-block transform rotate-[5deg] ml-2">U</span>
                    <span className="inline-block transform rotate-[5deg]">s</span>
                </h2>

                <h2 className='text-left text-lg md:text-xl'>Welcome to Omaxe Dwarka: Luxurious Living in the Heart of Delhi</h2>
                <p className='text-left text-sm md:text-base mt-2'>
                    Omaxe Retail is pulling out all stops to showcase how the future of retail would look like at its Mall located at sector 19B Dwarka.
                </p>

                <h2 className='text-left text-lg md:text-xl mt-4'>Why Choose Omaxe!</h2>
                <p className='text-left text-sm md:text-base mt-2'>
                    Coming up in an elevated, and well-developed micro-market of Dwarka a rapidly growing suburb of Delhi, Omaxe Mall enjoys optimal
                    accessibility to the entire NCR. This is a very strategic location since it is close to the Indira Gandhi International Airport and within close proximity to a linkage of 19 highways hence suggesting easy movement both for the occupants as well as investors.
                    <br /><br />
                    <span className='font-semibold text-black'>Renowned Developer:</span> Omaxe Mall is a signature commercial project that is masterfully designed 
                    to offer an aesthetic appearance with excellent functionality. It is a proud creation of Omaxe Group, a premium real estate developer with operations in residential and commercial sectors all over India.
                    <br /><br />
                    <span className='font-semibold text-black'>Contemporary Amenities:</span> Omaxe Mall has requisite infrastructural facilities like Wi-Fi, elevators, green landscape, and the distinctive features of having power and water on round the clock.
                </p>
                
                <button 
                    className='text-white bg-red-500 p-3 rounded-lg mt-4 hover:bg-red-700 transition-colors duration-300'
                    onClick={handleOpenModal}
                >
                    Enquire Now &gt;&gt;
                </button>
            </div>

            {/* Modal with Form */}
            <PopForm
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                title="Get Details"
            />
        </div>
    );
}
