import React from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa';

export default function Connectivity() {
  return (
    <div className="bg-[#d9c4aa] p-8" id='location'>
      <h2 className="text-center text-sm font-semibold tracking-wider text-gray-600">
        CONNECTIVITY
      </h2>
      <h1 className="mt-2 text-center text-2xl font-bold text-gray-800">
        Strategic Location to Shop, Socialise and Connect
      </h1>
      <p className="mt-4 text-center text-gray-600">
        Perfectly positioned, The Omaxe State offers seamless connectivity, making it the ideal choice for anyone looking for a Retail Shops & Food Court in Sector 19-B, Dwarka.
      </p>

      <div className="mt-8 flex flex-col lg:flex-row items-center justify-center gap-8">
        {/* Left side - Map */}
        <div className="w-full lg:w-2/3 max-w-lg lg:max-w-xl flex justify-center">
          <img
            src="images/Location.jpg" // Replace with the actual path of the image
            alt="Map showing connectivity"
            className="w-full rounded-lg shadow-md"
          />
        </div>

        {/* Right side - Feature list */}
        <div className="w-full lg:w-1/3 p-6 border-2 border-dotted border-gray-400 rounded-lg flex flex-col items-center">
          <FaMapMarkerAlt className="text-4xl text-gray-700" />
          <ul className="mt-4 space-y-2 text-gray-700 text-center">
            <li>3 major Metro Stations within 2 KM radius.</li>
            <li>Close Proximity with Indira Gandhi International Airport.</li>
            <li>Opp. 200 Acres Bharat Vandan Park.</li>
            <li>Fluent Connectivity through Dwarka Expressway.</li>
            <li>Connecting Gurugram through Urban Extension Road 2.</li>
            <li>Near to India's Longest Golf Course.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
